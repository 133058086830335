import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UserManagementService } from 'app/modules/partner/user-management/user-management.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { Language } from 'app/modules/user/releases/releases.types';

@Component({
    selector: 'app-send-notification-modal',
    templateUrl: './send-notification-modal.component.html',
    styleUrls: ['./send-notification-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendNotificationModal implements OnInit {
    form: FormGroup;

    constructor(public matDialogRef: MatDialogRef<SendNotificationModal>,
                private _changeDetectorRef: ChangeDetectorRef,
                private _formBuilder: FormBuilder,
                public userManagementService: UserManagementService,
                private _notificationService: NotificationsService,
                @Inject(MAT_DIALOG_DATA) public data: { userId: string, language: Language },
    ) {
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            subject: ['', Validators.required],
            body: ['', Validators.required],
        });
    }

    sendNotification() {
        if (this.form.invalid) {
            return;
        }

        let formData = this.form.getRawValue();
        let newNotification: Notification = {
            title: formData.subject,
            description: formData.body,
        }

        this._notificationService.create(newNotification, this.data.userId).pipe(take(1)).subscribe(_ => {
            this.matDialogRef.close();
        });
    }
}
