<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto md:w-200 md:min-w-200 min-h-60 max-h-90vh -m-6 relative" style="min-width: 80vw;">

        <div class="flex flex-row justify-between bg-gray-100 dark:bg-gray-700 p-4 border-b">
            <div class="font-semibold text-center flex items-center text-3xl">
                Send Notification
            </div>

            <button
                (click)="matDialogRef.close()"
                [tabIndex]="-1"
                mat-icon-button>
                <mat-icon
                    [svgIcon]="'heroicons_outline:x-mark'"
                    class="text-current"></mat-icon>
            </button>
        </div>

        <div class="p-4">
            <div>The user's preferred language is <b>{{data.language ? t("lang." + data.language.alpha3) : "unknown"}}</b>.</div>
            <form (ngSubmit)="sendNotification()" [formGroup]="form" class="w-full" id="notification-form">
                <mat-form-field class="w-full mt-2">
                    <input class="w-full" formControlName="subject" matInput name="notification-subject" placeholder="Subject">
                </mat-form-field>
                <mat-form-field class="w-full mt-2">
                    <textarea [rows]="5" class="w-full" formControlName="body" matInput name="notification-body" placeholder="Notification"></textarea>
                </mat-form-field>
            </form>

        </div>

        <div class="flex flex-auto flex-col sm:flex-row justify-end border-t bg-gray-100 dark:bg-gray-700 p-4 space-y-4 md:space-y-0">
            <div class="flex flex-col my-auto">
                <button
                    color="primary"
                    form="notification-form"
                    mat-flat-button
                    type="submit">
                    <span>{{t('send-button')}}</span>
                </button>
            </div>
        </div>

    </div>
</ng-container>
